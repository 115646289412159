import React from "react"
import { StaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

import colors from "src/assets/styles/colors.js"
const Cookies = () => (
  <StaticQuery
    query={graphql`
      query Privacy {
        strapiPrivacyPolicy {
          PDF {
            localFile {
              publicURL
            }
          }
        }
      }
    `}
    render={data => (
      <CookieConsent
        style={{ background: `${colors.mainColor}`, padding: "0px" }}
        buttonStyle={{ background: `#fff`, margin: "5px 0px 5px 15px" }}
        declineButtonStyle={{
          background: `transparent`,
          margin: "5px 15px 5px 5px",
          color: `${colors.darkGrey}`,
          textDecoration: "underline",
        }}
        contentStyle={{ margin: "5px 15px" }}
        location="bottom"
        buttonText="Akceptuję"
        enableDeclineButton
        flipButtons
        declineButtonText="Nie akceptuję"
        cookieName="gatsby-gdpr-google-analytics"
      >
        Nasza strona internetowa używa plików cookies. Zobacz{" "}
        <Link
          // href={data.strapiPrivacyPolicy.PDF.localFile.publicURL}
          to="/privacy-policy"
          target="blank"
        >
          politykę prywatności.
        </Link>
      </CookieConsent>
    )}
  />
)

export default Cookies
